<template>
  <div class="addAddress">
    <Header isNoBg="#0B0B0B" theme="dark">
      <template v-slot:default>填写收货信息</template>
    </Header>
    <div class="container">
      <div style="flex: 1">
        <div class="group">
          <div class="left">收货人</div>
          <div class="right">
            <input
              type="text"
              v-model="query.user_name"
              placeholder="请填写收货人姓名"
            />
          </div>
        </div>
        <div class="group">
          <div class="left">电话</div>
          <div class="right">
            <input
              type="text"
              v-model="query.tel"
              placeholder="请填写收货手机号码"
            />
          </div>
        </div>
        <div class="group">
          <div class="left">地区</div>
          <div class="right" @click="show = true">
            {{ fieldValue }}
          </div>
        </div>
        <div class="group">
          <div class="left">详细地址</div>
          <div class="right">
            <input
              type="text"
              v-model="query.detailed"
              placeholder="如街道、门牌号、小区等"
            />
          </div>
        </div>
      </div>
      <div class="botton" @click="submit">确定</div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import { city } from '../../api/config';
export default {
  data() {
    return {
      show: false,
      cascaderValue: '',
      options: city,
      fieldValue: '请选择省市区',
      query: {
        user_name: '',
        tel: '',
        province: '',
        city: '',
        county: '',
        detailed: '',
      },
    };
  },
  mounted() {
    this.token = this.$route.query.token;
  },
  methods: {
    onFinish({ selectedOptions }) {
      this.show = false;
      this.query.province = selectedOptions[0].text;
      this.query.city = selectedOptions[1].text;
      this.query.county = selectedOptions[2].text;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
    },
    async submit() {
      if (!this.query.user_name) return this.$toast('请填写收货人姓名');
      if (!this.query.tel) return this.$toast('请填写收货手机号码');
      if (this.fieldValue == '请选择省市区') return this.$toast('请选择省市区');
      if (!this.query.detailed) return this.$toast('请输入详细地址');
      const query = {
        ...this.query,
        token: this.token,
        positionId: this.$route.query.id,
      };
      const res = await this.$api.createdAddress(query);
      if (res.code === 0) {
        this.$router.back();
      } else {
        this.$toast(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.addAddress {
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  .container {
    padding: 0 18px 50px;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #313131;
    display: flex;
    flex-direction: column;
    .group {
      display: flex;
      align-items: center;
      padding: 20px 0;
      &:not(:last-child) {
        border-bottom: 1px solid #3d3d3d;
      }
      .left {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        width: 80px;
      }
      .right {
        flex: 1;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        input {
          background: transparent;
          outline: none;
          border: none;
        }
      }
    }
    .botton {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      width: 100%;
      height: 40px;
      background: #8d000a;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
